
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonButton,
  IonText,
  IonItem,
  IonLabel,
  IonSpinner,
  IonBackButton,
  toastController,
  IonButtons,
  IonTextarea,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import { API_URL } from "@/providers/constants";
import { Storage } from "@capacitor/storage";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonButton,
    IonText,
    IonItem,
    IonLabel,
    IonSpinner,
    IonBackButton,
    IonButtons,
    IonTextarea,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      username: "",
      password: "",
      responseStatus: "",
      loading: false,
      productName: "",
      productSku: "",
      productDescription: "",
      productPrice: "",
      productCategories: [],
      productBrand: "",
      categories: {},
      selected: "",
      token: "",
    };
  },
  mounted() {
    this.getToken()
    this.productSku = this.$route.params.productSku;
    setTimeout(() => {
      this.getProduct()
    }, 500)
  },
  methods: {
    async getProduct() {
      this.loading = true;

      this.productUrl = API_URL + "/rest/V1/products/" + this.productSku;

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };
      axios
        .get(this.productUrl, { headers })
        .then((response) => {
          if (response.data) {
            this.product = response.data;
            this.productSku = this.product.sku;
            this.productName = this.product.name;
            this.productPrice = this.product.price;
            this.fetchCategories();
            this.getAttributes();
          } else
            this.openToast(
              "No items with name or SKU " + this.search + " were found"
            );
          this.loading = false;
        })
        .catch((error) => {
          if (error.response)
            this.openToast(
              "Session timeout, please exit the app and login again"
            );
          this.loading = false;
        });
    },
    async openToast(message) {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    },
    async getAttributes() {
      const attributes = this.product.custom_attributes;

      const categories = this.product.extension_attributes.category_links;

      const categoryIds = [];

      categories.forEach((categorie) => {
        this.productCategories.push(categorie.id);
      });

      attributes.forEach((attribute: any) => {
        if (attribute.attribute_code == "description") {
          this.productDescription = attribute.value;
        } else if (attribute.attribute_code == "fb_product_brand")
          this.productBrand = attribute.value;
      });
    },
    async fetchCategories() {
      this.loading = true;

      this.categoriesUrl =
        API_URL + "/rest/V1/categories/list?searchCriteria[page_size]=1000";

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

      axios
        .get(this.categoriesUrl, { headers })
        .then((response) => {
          if (response.data.items.length) this.categories = response.data.items;
          else this.openToast("No items with name or SKU were found");
          this.loading = false;
        })
        .catch((error) => {
          if (error.response)
            this.openToast(
              "Session timeout, please exit the app and login again"
            );
          this.loading = false;
        });
    },
    async getToken() {
      const { value } = await Storage.get({ key: "token" });
      this.token = value;
    },
    async edit() {
      console.log("Will do")
    }
  },
});
