<template>
  <ion-page>
    <ion-header :translucent="true" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons>
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Edit Product</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div id="container">
        <div id="create-container">
          <ion-text>Edit Product</ion-text>
          <ion-item>
            <ion-label position="floating">Name</ion-label>
            <ion-input v-model="productName" clear-input></ion-input>
          </ion-item>

          <ion-item>
            <ion-label position="floating">SKU</ion-label>
            <ion-input
              :disabled="true"
              v-model="productSku"
              clear-input
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Description</ion-label>
            <ion-textarea
              type="textarea"
              v-model="productDescription"
              clear-input
            ></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Price</ion-label>
            <ion-input
              v-model="productPrice"
              type="number"
              clear-input
            ></ion-input>
          </ion-item>
          <!-- <ion-item>
                <ion-label position="floating">Categories</ion-label>
                <ion-input v-model="productCategories" clear-input></ion-input>
            </ion-item> -->
          <ion-item>
            <ion-label>Categories</ion-label>
            <ion-select multiple v-model="productCategories">
              <ion-select-option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
                >{{ category.name }}</ion-select-option
              >
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label>Brand</ion-label>
            <ion-select :value="productBrand">
              <ion-select-option value="DM">DM</ion-select-option>
              <ion-select-option value="FL">FL</ion-select-option>
              <ion-select-option value="AY">AY</ion-select-option>
              <ion-select-option value="TJ">TJ</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-button :disabled="loading" @click="edit" id="edit-button" expand="block"
            >Submit <ion-spinner name="lines-small" v-if="loading"></ion-spinner
          ></ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonButton,
  IonText,
  IonItem,
  IonLabel,
  IonSpinner,
  IonBackButton,
  toastController,
  IonButtons,
  IonTextarea,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import { API_URL } from "@/providers/constants";
import { Storage } from "@capacitor/storage";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonButton,
    IonText,
    IonItem,
    IonLabel,
    IonSpinner,
    IonBackButton,
    IonButtons,
    IonTextarea,
    IonSelect,
    IonSelectOption,
  },
  data() {
    return {
      username: "",
      password: "",
      responseStatus: "",
      loading: false,
      productName: "",
      productSku: "",
      productDescription: "",
      productPrice: "",
      productCategories: [],
      productBrand: "",
      categories: {},
      selected: "",
      token: "",
    };
  },
  mounted() {
    this.getToken()
    this.productSku = this.$route.params.productSku;
    setTimeout(() => {
      this.getProduct()
    }, 500)
  },
  methods: {
    async getProduct() {
      this.loading = true;

      this.productUrl = API_URL + "/rest/V1/products/" + this.productSku;

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };
      axios
        .get(this.productUrl, { headers })
        .then((response) => {
          if (response.data) {
            this.product = response.data;
            this.productSku = this.product.sku;
            this.productName = this.product.name;
            this.productPrice = this.product.price;
            this.fetchCategories();
            this.getAttributes();
          } else
            this.openToast(
              "No items with name or SKU " + this.search + " were found"
            );
          this.loading = false;
        })
        .catch((error) => {
          if (error.response)
            this.openToast(
              "Session timeout, please exit the app and login again"
            );
          this.loading = false;
        });
    },
    async openToast(message) {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    },
    async getAttributes() {
      const attributes = this.product.custom_attributes;

      const categories = this.product.extension_attributes.category_links;

      const categoryIds = [];

      categories.forEach((categorie) => {
        this.productCategories.push(categorie.id);
      });

      attributes.forEach((attribute: any) => {
        if (attribute.attribute_code == "description") {
          this.productDescription = attribute.value;
        } else if (attribute.attribute_code == "fb_product_brand")
          this.productBrand = attribute.value;
      });
    },
    async fetchCategories() {
      this.loading = true;

      this.categoriesUrl =
        API_URL + "/rest/V1/categories/list?searchCriteria[page_size]=1000";

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

      axios
        .get(this.categoriesUrl, { headers })
        .then((response) => {
          if (response.data.items.length) this.categories = response.data.items;
          else this.openToast("No items with name or SKU were found");
          this.loading = false;
        })
        .catch((error) => {
          if (error.response)
            this.openToast(
              "Session timeout, please exit the app and login again"
            );
          this.loading = false;
        });
    },
    async getToken() {
      const { value } = await Storage.get({ key: "token" });
      this.token = value;
    },
    async edit() {
      console.log("Will do")
    }
  },
});
</script>
<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 16px;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
#logo {
  margin-bottom: 40px;
}
#login-container {
  margin-bottom: 20px;
}
#info {
  font-size: 10px;
}
#login-button {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>